.new-farm {
    background-color: lightblue;
    padding: 1rem;
    margin: 2rem auto;
    width: 75%;
    border-radius: 12px;
    text-align: center;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  }
  
  .new-farm button {
    width: 9rem;
    height: 3rem;
    border-radius: 4px;
    background-color: white;
    text-align: center;
    font-size: 70%;
    font: inherit;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
    margin: 1rem;
    text-decoration: none;
    color: black;
  }
  
  .new-farm button:hover,
  .new-farm button:active {
    transform: scale(1.05);
    background-color: lightgray;
    transition: 0.2s;
    cursor: pointer;
  }