.guidance-wrapper {
    font-family: Georgia, 'Times New Roman', Times, serif;
    text-align: center;
    box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    width: 75%;
    margin: auto;
    margin-top: 3rem;
    padding: 0.5rem;
    padding-bottom: 2.25rem;
}

.guidance-tabs {
    height: 60vh !important;
}

.guidance-panel {
    width: 90%;
    overflow-y:auto;
    align-items: center;
    text-align: center;
}

table, th, td {
    border: 1px solid;
  }