.radio {
    background-color: lightgray;
    border-radius: 4px;
    box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1);
    width: 45%;
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
    padding: 0.35rem;
    display: inline-block;
}

.entry {
    float: left;
    margin: 0.1rem;
    padding: 0.2rem;
    padding-right: 0.5rem;
}

.label {
    padding-left: 0.2rem;
}