.questions-list {
    align-items: center;
    background-color: #f8f8ff;
    margin: 2rem auto;
    width: 50rem;
    max-width: 95%;
    border-radius: 8px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
    font-family: 'Noto Sans', sans-serif;
    font-weight: 400;
    font-size: 18px;
}

.question {
    text-align: left;
    padding: 2rem;
}

.input-text {
    display: block;
    border: none;
    border-radius: 12px;
    width: 100%;
    padding: 3% 5%;
    box-sizing: border-box;
    background: white;
    font-size: 1rem;
    font-family: 'Times New Roman', Times, serif;
    color: black;
    line-height: 1.2;
    box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.05);
    font-weight: 900;
    resize: none;
  }

  .row {
    display: inline-block;
    margin-left: -0.75rem;
}

  .header {
    display: inline-block;
    align-items: center;
    justify-content: center;
  }

  .header-h1 {
    font-family: 'Times New Roman', Times, serif;
    font-size: 3.5rem;
    float: left;
    margin-top: 2rem;
  }

  .header-h3 {
    font-family: 'Times New Roman', Times, serif;
    font-size: 1.75rem;
    margin: 2rem;
  }

  .header-p {
    font-family: 'Times New Roman', Times, serif;
    font-size: 1.25rem;
    margin: 2rem;
  }

  .logo {
    width: 40%;
    height: auto;
    margin: 2rem;
    float: left;
}

.link-q {
  text-decoration: none;
  color: black;
}

@media screen and (max-width: 960px) { 
  .header-h1 {
    font-size: 5vw;
  }
}