.button {
    width: 9rem;
    height: 3rem;
    border-radius: 4px;
    background-color: white;
    text-align: center;
    font-size: 90%;
    font-family: Verdana;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
    margin: 1.5rem;
}

.button-small {
    width: 7rem;
    height: 2rem;
    border-radius: 4px;
    background-color: white;
    text-align: center;
    font-size: 70%;
    font-family: Verdana;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
    margin: 1rem;
    float: left;
    text-decoration: none;
    color: black;
}

.float-child {
    float: left;
}

.button:hover, .button-small:hover {
    transform: scale(1.05);
    background-color: lightgray;
    transition: 0.2s;
    cursor: pointer;
}