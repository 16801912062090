body {
  background-color: whitesmoke;
}

.Login {
  font-family: Georgia, 'Times New Roman', Times, serif;
  text-align: center;
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  width: 30%;
  margin: auto;
  margin-top: 5rem;
  padding: 0.5rem;
}

.login-input {
  display: block;
  border: none;
  border-radius: 12px;
  width: 90%;
  padding: 3% 5%;
  margin: 1rem;
  box-sizing: border-box;
  background: white;
  font-size: 1rem;
  font-family: 'Times New Roman', Times, serif;
  color: black;
  line-height: 1.2;
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.05);
  font-weight: 900;
  resize: none;
}

@media screen and (max-width: 960px) { 
  .Login {
      width: 60%;
  }
}