.farm-filter {
    display: block;
    width: 25%;
    padding: 2rem;
    align-items: right;
    text-align: right;
    background-color: lightblue;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
    justify-content: space-between;
    margin: 1rem 0;
  }
  
  .farm-filter label {
    display: block;
    font-weight: bold;
    text-align: right;
    margin-bottom: 0.5rem;
  }
  
  .farm-filter select {
    font: inherit;
    padding: 0.5rem 1rem;
    font-weight: bold;
    border-radius: 6px;
  }

  .top-wrapper {
      display: flex;
  }

  .bottom-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .farm-item {
    display: flex;
    transform: translateX(-1vw);
    justify-content: space-between;
    align-items: center;
    width: 67.5vw;
    border-radius: 8px;
    padding: 0.5rem;
    margin: 1rem 0;
    background-color: gray;
  }

  .farm-item h2 {
    font-size: 1.25rem;
    flex: 1;
    margin: 0.25rem;
    font-weight: lighter;
    margin-left: 0.75rem;
  }

  .farm-description {
    background-color: lightgray;
    border-radius: 8px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
    margin-right: 4rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-flow: column;
    justify-content: flex-start;
    flex: 1;
  }

  .farm-status {
    background-color: lightblue;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    gap: 1rem;
    align-items: flex-end;
    flex-flow: column;
    justify-content: flex-start;
    flex: 1;
  }

  .farm-buttons {
    display: flex;
    flex-direction: row-reverse;
  }

  .farm-status h2 {
    font-size: 1.25rem;
    flex: 1;
    margin-top: 0.75rem;
    margin-right: 0.5rem;
    margin-bottom: 0;
    font-weight: lighter;
    margin-left: 0.75rem;
  }

  .link-q {
    text-decoration: none;
    color: black;
  }
  .button {
    width: 9rem;
    height: 3rem;
    border-radius: 4px;
    background-color: white;
    text-align: center;
    font-size: 90%;
    font-family: Verdana;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
    margin: 0.5rem;
}
.button:hover{
    transform: scale(1.05);
    background-color: lightgray;
    transition: 0.2s;
    cursor: pointer;
}

.loc-div {
  display: flex; 
  justify-content: space-between;
}

.loc-div p {
  font-size: 1.0rem;
  margin: 0.25rem;
  font-weight: lighter;
  text-align: left;
  margin-top: 0.45rem;
}

  @media screen and (max-width: 960px) { 
    .farm-filter select {
      font-size: 0.5rem;
      padding: 0.5rem 0.5rem;
      font-weight: unset;
      width: 6rem;
    }
  }