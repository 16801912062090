.status {
    background-color: lightblue;
    width: 47.5%;
    padding: 1rem;
    align-items: center;
    margin-left: 12.5%;
    margin-right: 2.5%;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
}

.status-bar {
    height: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
.status-bar-inner {
    height: 100%;
    width: 100%;
    margin: 0.25rem;
    border: 1px solid #313131;
    border-radius: 12px;
    background-color: #c3b4f3;
    overflow: hidden;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
  
  .status-bar-fill {
    background-color: #4826b9;
    height: 100%;
    transition: all 0.3s ease-out;
  }
  
  .status-bar-label {
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
  }