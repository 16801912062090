body {
    background-color: whitesmoke;
  }
  
  .About {
    font-family: Georgia, 'Times New Roman', Times, serif;
    text-align: center;
    box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    width: 60%;
    margin: auto;
    margin-top: 5rem;
    padding: 0.5rem;
    padding-bottom: 2.25rem;
  }

.p-text {
    margin: 0.8rem;
    padding: 1rem;
    font-size: 1.3rem;
}

.button-wrapper {
    padding: 1.5rem;
}

.learn-more-button {
    width: 9rem;
    height: 3rem;
    font-size: 1.25rem;
    border-radius: 4px;
    background-color: white;
    text-align: center;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
    border: 2px solid black;
    color: black;
    text-decoration: none;
    padding: 1rem;
}

  .learn-more-button:hover {
    transform: scale(1.05);
    background-color: lightgray;
    transition: 0.2s;
    cursor: pointer;
  }

  @media screen and (max-width: 960px) { 
    .About {
        width: 80%;
    }
  }
