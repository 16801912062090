.main-wrapper {
    height: 30vh;
    display: flex;
    flex-wrap: wrap;
    align-items: left;
  }
  .info-wrapper {
    margin-top: 7rem;
    font-weight: bold;
  }
  .map-details {
    text-align: left;
    font-size: 1rem;
  }
  .map-details span {
    font-weight: normal;
  }
  .search-input {
    font: inherit;
    padding: 0.5rem;
    border-radius: 9px;
    margin-left: -0.5rem;
    border: none;
    width: 30vw;
    max-width: 100%;
    box-sizing: border-box;
    background: white;
    font-size: 1rem;
    font-family: 'Times New Roman', Times, serif;
    line-height: 1.2;
    box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.05);
    font-weight: 900;
    resize: none;
  }  

  .search-label {
    font-weight: bold;
    margin-bottom: 0.5rem;
    margin-left: -0.5rem;
    display: block;
    text-align: left;
  }

  .wrapper {
    width: 30vw;
    height: 100%;
  }

  
  @media screen and (max-width: 960px) { 
    .search-input {
      width: 50vw;
    }

    .wrapper {
        width: 50vw;
    }
  }