.farm-form {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 2rem;
    text-align: left;
  }
  
  .farm-form-div label {
    font-weight: bold;
    margin-bottom: 0.5rem;
    display: block;
  }
  
  .farm-form-div input {
    font: inherit;
    padding: 0.5rem;
    border-radius: 9px;
    border: none;
    width: 20rem;
    max-width: 100%;
    box-sizing: border-box;
    background: white;
    font-size: 1rem;
    font-family: 'Times New Roman', Times, serif;
    line-height: 1.2;
    box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.05);
    font-weight: 900;
    resize: none;
  }
  
  .farm-form-final {
    text-align: right;
  }

  @media screen and (max-width: 960px) { 
    .farm-form-final {
      margin-top: 0;
    }
  }